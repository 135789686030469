$border-radius: 2px;
$white: #ffffff;
$card-shadow: 0 1px 2px rgba(0,0,0,0.075);

@mixin animate($name, $duration) {
  -webkit-animation-name: $name;
  animation-name: $name;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

// Card
@mixin card() {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $card-shadow;
}

.form-group--centered {
  .form-control {
    text-align: center;
  }
}

.login {
  padding-top: 1.2rem;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.login__block {
  max-width: 330px;
  width: 100%;
  display: none;
  text-align: center;
  padding: 1.2rem;
  @include animate(fadeInUp, 300ms);
  @include card();
  display: block;
  margin: 1em auto;
}

.login__block.active {
  z-index: 10;
  display: block;
  p {
    margin: 0;
    a {
      color: #32c787;
    }
  }
}

.login__block__header {
  padding: 1.5rem;
  margin-top: -2.4rem;
  margin-bottom: 1.5rem;
  position: relative;
  color: $white;
  border-radius: $border-radius;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.18);
  background-color: $basic-color;

  & > i,
  & > img {
    display: block;
    margin-bottom: 0.8rem;
  }

  & > i {
    font-size: 3rem;
  }

  & > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.33);
  }
}

.login__block__actions {
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  .dropdown:not(.show) {
    display: none;
  }
}

.login__block__body {
  padding: 1rem;
}

.login__block__btn {
  margin-top: 0.5rem;
  background-color: #32c787;
  border-radius: 2px;

  &,
  &:hover,
  &:focus {
    color: $white;
  }

  &:hover {
    opacity: 0.9;
  }
}

.form-group--float .form-control + label {
  width: 100%;
}

label {
  font-weight: 400 !important;
}

/*
  Submit/Login/Register Button
 */

.btn-auth {
  padding: .8rem 4rem;
  font-size: 1em;
}

/*
  Banner Image
 */

.banner {
  text-align: center;
  padding-top: 0;
  width: 100%;
  margin: 0 auto;

  a {
    display: block;
    padding: 5px 0;
    line-height: 65px;
    background-color: $theme-color-school;
  }

  img {
    width: 200px;
    display: inline-block;
  }

}

.reset-link {
  margin-bottom: 1.5rem !important;
  color: #32c787;
  margin-top: -14px !important;
  text-align: left;
}