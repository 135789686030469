@import "../inner-imports";

.card {
  margin-bottom: 0;
}

.info-icon {
  color: sandybrown;
  cursor: pointer;
}

.form-group--float {
  margin-top: 0;
}

.form-group {
  margin-bottom: 3rem;
}

.info-group {
  margin: 35px 0;
}

.info-group:first-of-type {
  margin-top: 5px;
}

.info-group:last-of-type {
  margin-bottom: 45px;
}

.card-header, .card-header-black {
  padding: 1.5rem 2.1rem 1.5rem;
  border-radius: 4px 4px 0 0;
}

.card-block {
  margin-top: 15px;
}

.card-header-black {
  background-color: $card-header-color;
}

.card-title {
  color: #fff;
  font-size: 1.50rem;
}

.form-group--textarea label {
  color: #636c72;
  margin: 0;
  margin-left: 0.1rem;
  font-size: 0.875rem;
}

fieldset .prime-overlay {
  height: 65%;
  top: -13px;
}

.prime-overlay {
  height: 65%;
  top: -13px;
}

.custom-control.custom-radio {
  margin-left: 2rem !important;
}

.vision-input .prime-overlay{
  height: unset !important;
}

@media screen and (max-width: 479px) {
  fieldset .prime-overlay {
    height: 100% !important;
    top: unset;
  }
  .prime-overlay {
    height: 65% !important;
    top: -10px;
  }
  .vision-input .prime-overlay{
    height: 100% !important;
  }

}

.fc-past {
    background-color: silver;
}
