// .content {
//   @include media-breakpoint-up(xl) {
//     padding: ($header-height + $grid-gutter-width-base) $grid-gutter-width-base 0 $sidebar-width;
//   }

.content {
  @include media-breakpoint-up(xl) {
    padding: ($header-height + 0.675rem) 1.875rem 0 ($sidebar-width + 1.875rem);
  }


  // @include media-breakpoint-between(sm, lg) {
  //   padding: ($header-height + $grid-gutter-width-base) $grid-gutter-width-base 0;
  // }


  @include media-breakpoint-between(sm, lg) {
    padding: ($header-height + 0.675rem) 1.875rem 0;
  }

  //   @include media-breakpoint-down(xs) {
  //     padding: ($header-height + $grid-gutter-width-base/2) $grid-gutter-width-base/2 0;
  //   }
  // }

  @include media-breakpoint-down(xs) {
    padding: ($header-height + 0.675rem/2) 1.875rem 0;
  }
}

//Minimum Height Of Content For Fixed Footer Irrespective Of Less Content In Main Content Area

.fixed-height {
  min-height: 615px;
}

.content__inner {
  margin: auto;

  &:not(.content__inner--sm) {
    max-width: 1200px;
  }
}

.content__inner--sm {
  max-width: 800px;
}

.content__title {
  padding: .6rem 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  & > h1 {
    line-height: 100%;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    white-space: normal;
    text-transform: uppercase;
    // color: $content-title-heading-color;
    color: #676767;
  }

  .actions {
    margin-left: auto;
  }

  & > small {
    font-size: 1rem;
    display: block;
    margin-top: 0.8rem;
    color: lighten($content-title-heading-color, 18%);
  }
}

.dashboard {
  @include clearfix();
  margin: 0 (-$grid-gutter-width-base/2);
}

.dashboard__column {
  float: left;
  padding: 0 $grid-gutter-width-base/2;

  @media(min-width: 1600px) {
    width: 33.333333%;
  }

  @media(min-width: 800px) and (max-width: 1599px) {
    width: 50%;
  }

  @media(max-width: 799px) {
    width: 100%;
  }
}


.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #efefef;
  background-color: #efefef;
  background: -webkit-gradient(linear, left top, left bottom, from(#efefef), to(#efefef));
  background: linear-gradient(to bottom, #efefef 0%, #efefef 100%);
}

#DataTables_Table_0_previous .page-link {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

