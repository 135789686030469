@import "../variables";

.border-right {
  border-right: 1px solid rgba(34, 33, 33, 0.25) !important;
}

.border-top {
  border-top: 1px solid rgba(34, 33, 33, 0.25) !important;
}

$dense-white: #e2e2e2;

table.dataTable {
  > thead {
    .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
      &:before {
        content: "";
      }

      &:after {
        content: "";
      }
    }
  }
}


//table.dataTable > tbody > tr.child > td.child > ul.dtr-details > li {
//  &:first-child {
//    margin-left: 5.25rem !important;
//  }
//
//  margin-left: 1rem !important;
//
//  .dtr-title {
//    min-width: 2rem !important;
//  }
//}
//
//.tab-content {
//  padding: 0.5rem 0 !important;
//}
//
//table.dataTable.display tbody tr.odd > .sorting_1,
//table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
//  background-color: transparent !important;
//}
//
//tr.parent.odd, tr.parent.odd + tr.child {
//  background-color: $dense-white !important;
//}
//
//tr.parent.odd + tr.child:hover {
//  background-color: $dense-white !important;
//}
//
//tr.parent.even, tr.parent.even + tr.child {
//  background-color: $white !important;
//}
//
//tr.parent.even + tr.child:hover {
//  background-color: $white !important;
//}

//
//.dt-buttons {
//  margin-right: 2.5rem !important;
//  float: left !important;
//}
//
//.dtr-details {
//  display: inline-flex !important;
//}

//
//.template-text-preview,
//.template-text-preview h1,
//.template-text-preview h2,
//.template-text-preview h3,
//.template-text-preview h4,
//.template-text-preview h5,
//.template-text-preview h6,
//.template-text-preview label,
//.template-text-preview strong {
//  font-size: 1rem;
//  font-weight: normal;
//}
//
//
//.modal-dialog {
//  width: 100% !important;
//}

.small-text{
  font-size: 1rem!important;
}

.font-italic {
  font-style: italic!important;
}

.font-weight-normal {
  font-weight: 400 !important;
}


#mobilePricing {
  .panel.card{
    .panel-body.card-body{
      margin-top: 0 !important;
      padding-top: 1rem !important;
    }
  }

  .card-header.panel-enabled {
    padding: 0 !important;

    .panel-title {
      [role="button"] {

        &[aria-expanded="false"] {
          button {
            &:after {
              font: normal bold normal 1.25rem/1 Material-Design-Iconic-Font !important;
              content: "\f278" !important;
              float: right !important;
            }
          }
        }

        &[aria-expanded="true"] {
          button {
            &:after {
              font: normal bold normal 1.25rem/1 Material-Design-Iconic-Font !important;
              content: "\f273" !important;
              float: right !important;
            }
          }
        }

        button[type="button"]{
          width: 100% !important;
          padding: 2rem !important;
          font-size: 1rem !important;
          font-weight: bold !important;
          color: #000000 !important;
          text-transform: uppercase !important;
        }
      }
    }
  }
}


[name="DataTables_Table_0_length"]{
  width: 40px!important;
}
