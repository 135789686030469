.flot-tooltip {
  position: absolute;
  line-height: 100%;
  color: $body-color;
  display: none;
  font-size: 0.95rem;
  box-shadow: 0 3px 5px rgba($black, 0.08);
  border-radius: $border-radius;
  padding: 0.7rem 1rem;
  background-color: $white;
  z-index: 99999;
}