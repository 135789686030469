.alert {
  font-weight: 500;
}

[class*="alert-"] {
  .alert-link {
    color: $white;
    border-bottom: 1px dotted rgba($white, 0.5);
  }
}

.alert-heading {
  font-weight: 500;
  font-size: 1.3rem;
  margin-top: 0.15rem;
}

.alert-dismissible {
  .close {
    & > span:not(.sr-only) {
      background-color: rgba($white, 0.2);
      line-height: 19px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      font-size: 1.1rem;
      display: block;
    }

    &:hover,
    &:focus {
      color: $white;

      span:not(.sr-only) {
        background-color: rgba($white, 0.3);
      }
    }
  }
}