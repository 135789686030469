/**
* These styles override the default styling of ngx-progressbar
* They are used in xhr requests
* !important is needed for each override.
 */

ng-progress {
  .ng-progress {
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    zoom: 1;
    filter: alpha(opacity=0);
    opacity: 0;
    transition: opacity 200ms linear;
  }

  .active {
    filter: alpha(opacity=100);
    opacity: 1;
    transition: none;
  }

  .bar {
    position: absolute;
    width: 100%;
    height: 2px;
  }

  .thick   .bar {
    height: 3px;
  }

  .bar-shadow {
    display: block;
    position: absolute;
    right: 0;
    top: -3px;
    width: 100px;
    height: 100%;
    opacity: 1.0;
    -webkit-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    transform: rotate(3deg);
  }


  .thick   .bar-shadow {
    top: -4px;
    -webkit-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    transform: rotate(4deg);
  }

  .thick   .spinner-icon {
    width: 24px;
    height: 24px;
    border: solid 3px transparent;
  }


  .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 62px !important;
    left:50%;
  }

  .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border-top-color: rgba(0, 0, 0, 0) !important;
    border-left-color: #2196f3 !important;

    border: solid 2px transparent;
    border-radius: 50%;

    -webkit-animation: nprogress-spinner 500ms linear infinite !important;
    -moz-animation: nprogress-spinner 500ms linear infinite !important;
    animation: nprogress-spinner 500ms linear infinite !important;
  }

  .anti-clockwise   .spinner-icon {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: rotate(0deg);
    animation-direction: reverse;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}