@import "../inner-imports";

$active-color: $theme-color-school;
$common-radius: 3px;

.ng2ImgUpload {
  background-color: #f8f8f8 !important;
  border-radius: $common-radius !important;
  border: #d0d0d0 dashed 1px !important;
  font-family: sans-serif !important;
  position: relative !important;
  color: #9b9b9b !important;
  margin-top: -7px;
  margin-bottom: 40px;
}

.ng2ImgUpload .img-ul-file-is-over {
  border: $active-color solid !important;
}

.ng2ImgUpload .img-ul-hr-inline-group:after {
  clear: both !important;
  content: "" !important;
  display: table !important;
}

.ng2ImgUpload .img-ul-file-upload {
  padding: 3px 16px !important;
  display: flex;
  align-items: center;
}

.ng2ImgUpload .img-ul-drag-box-msg {
  display: inline-block !important;
  font-weight: 600 !important;
  margin-left: 12px !important;
  padding-top: 0px !important;
}

.ng2ImgUpload label.img-ul-button input[type=file] {
  display: none !important;
  position: fixed !important;
  top: -99999px !important;
}

.ng2ImgUpload .img-ul-upload {
  background-color: $active-color !important;
}

.ng2ImgUpload .img-ul-button {
  -moz-box-shadow: 2px 1px 3px 0 rgba(148, 148, 148, 0.6) !important;
  -webkit-box-shadow: 2px 1px 3px 0 rgba(148, 148, 148, 0.6) !important;
  box-shadow: 2px 1px 3px 0 rgba(148, 148, 148, 0.6) !important;
  color: #FFF !important;
  cursor: pointer !important;
  display: inline-block !important;
  float: left !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  padding: 6px 10px !important;
  text-transform: uppercase !important;
  margin: 0;
}

.ng2ImgUpload .img-ul-clear {
  background-color: #FF0000 !important;
  display: none !important;
}

.ng2ImgUpload .img-ul-button:active span {
  display: block !important;
  position: relative !important;
  top: 1px !important;
}

.ng2ImgUpload .img-ul-container {
  background-color: #fdfdfd !important;
  padding: 0 10px !important;
}

.ng2ImgUpload .img-ul-image {
  /*background: center center no-repeat !important;*/
  background-size: cover !important;
  display: inline-block !important;
  float: left !important;
  height: 40px !important;
  margin: 6px !important;
  position: relative !important;
  width: 40px !important;
}

.ng2ImgUpload .img-ul-x-mark {
 /* display: none !important;*/
  background-color: #000 !important;
  border-radius: 2px !important;
  color: #FFF !important;
  cursor: pointer !important;
  float: right !important;
  height: 20px !important;
  margin: 2px !important;
  opacity: .7 !important;
  text-align: center !important;
  width: 20px !important;
}

.ng2ImgUpload .img-ul-close {
  height: 20px !important;
  opacity: .7 !important;
  padding-right: 3px !important;
  position: relative !important;
  width: 20px !important;
}

.ng2ImgUpload .img-ul-x-mark:hover .img-ul-close {
  opacity: 1 !important;
}

.ng2ImgUpload .img-ul-close:before, .img-ul-close:after {
  background-color: #FFF !important;
  border-radius: 2px !important;
  content: '' !important;
  height: 15px !important;
  position: absolute !important;
  top: 0 !important;
  width: 2px !important;
}

.ng2ImgUpload .img-ul-close:before {
  transform: rotate(45deg) !important;
}

.ng2ImgUpload .img-ul-close:after {
  transform: rotate(-45deg) !important;
}

.ng2ImgUpload .img-ul-loading-overlay {
  background-color: #000 !important;
  bottom: 0 !important;
  left: 0 !important;
  opacity: .7 !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

.ng2ImgUpload .img-ul-spinning-circle {
  height: 30px !important;
  width: 30px !important;
  margin: auto !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  border-radius: 50% !important;
  border: 3px solid rgba(255, 255, 255, 0) !important;
  border-top: 3px solid #FFF !important;
  border-right: 3px solid #FFF !important;
  -webkit-animation: spinner 2s infinite cubic-bezier(0.085, 0.625, 0.855, 0.360) !important;
  animation: spinner 2s infinite cubic-bezier(0.085, 0.625, 0.855, 0.360) !important;
}

.ng2ImgUpload .img-ul-file-too-large {
  color: red !important;
  padding: 0 15px !important;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
  }

  100% {
    -webkit-transform: rotate(360deg) !important;
    transform: rotate(360deg) !important;
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
  }

  100% {
    -webkit-transform: rotate(360deg) !important;
    transform: rotate(360deg) !important;
  }
}

.pic-with-x .img-ul-x-mark {
  display: block !important;
}
