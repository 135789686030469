.btn {
  cursor: pointer;

  &:not(.btn-secondary) {
    box-shadow: $btn-box-shadow;
  }
}

.btn-secondary {
  &,
  &:focus {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  }
}

.btn--white {
  &,
  &:hover,
  &:focus {
    background-color: $white;
    color: $body-color;
  }
}

.btn--icon {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0;
  font-size: 1.2rem;
  text-align: center;
}

.btn--icon-text {
  & > .zmdi {
    font-size: 1.2rem;
    display: inline-block;
    vertical-align: top;
    margin: 1px 5px 0 0;
  }
}

.btn-custom {
  background-color: #e74c3c;
  color: #ffffff;
  border-color: #e74c3c;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.9;
  }

}