label {
  font-weight: 500;
}

.form-control {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  resize: none;
  @include appearance(none);
  -ms-overflow-style: none;

  &:focus {
    & ~ .form-group__bar {
      &:before,
      &:after {
        width: 50%;
      }
    }
  }

  &:disabled,
  &[readonly] {
    @include opacity(0.6);
  }
}


// Form Group
.form-group {
  position: relative;
}

.form-group__bar {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;

  &:before, &:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: 0;
    @include transition(all 200ms);
    @include transition-timing-function(ease);
    background-color: $light-blue;
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }
}

select::-ms-expand {
  display: none;
} 

.select {
  @include select-bg();
}

@mixin form-group-float($bottom-offset-active, $active-font-size) {

  &:focus ~ label,
  &.form-control--active ~ label {
      bottom: $bottom-offset-active;
      font-size: $active-font-size;
  }
}

.form-group--float {
  margin-top: 2rem;

  .form-control {
    @include form-group-float($input-padding-y*4, $font-size-sm);

    & + label {
      font-size: $font-size-base;
      bottom: $input-padding-y;
    }
  }

  .form-control-sm {
    @include form-group-float($input-padding-y-sm*4, $font-size-sm);

    & + label {
      font-size: $font-size-sm;
      bottom: $input-padding-y-sm;
    }
  }

  .form-control-lg {
    @include form-group-float($input-padding-y-lg*4, $font-size-base);

    & + label {
      font-size: $font-size-lg;
      bottom: $input-padding-y-lg;
    }
  }

  & > label {
    color: $input-color-placeholder;
    pointer-events: none;
    left: $input-padding-x;
    position: absolute;
    margin: 0;
    @include transition(bottom 150ms ease, color 150ms, font-size 150ms ease);
  }
}
