// Pie Charts
.chart-pie {
  display: inline-block;
  position: relative;
}

.chart-pie__value {
  position: absolute;
  color: $white;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  height: 100%;

  &:after {
    content: "%";
    font-size: 12px;
  }
}

.chart-pie__title {
  margin-top: -2px;
  line-height: 15px;
  font-size: 11px;
}


// Flot Chart Legends
.flot-chart-legends {
  text-align: center;
  margin: 30px 0 -10px;

  table {
    display: inline-block;
  }

  .legendColorBox {
    & > div {
      & > div {
        border-radius: 50%;
      }
    }
  }

  .legendLabel {
    padding: 0 8px 0 3px;
  }
}