/*------------------------------------------------------------------
  Project:	Material Admin Angular 2
  Author:   Rushan [http://bootstrapsale.com]
--------------------------------------------------------------------

[Table of Contents]
1.  Bootstrap

2.  Third Party Vendors

3.  Site Variable and Mixin

4.  Fonts

5.  Bootstrap Overrides
    i.      Alert
    ii.     Breadcrumb
    iii.    Button
    iv.     Close
    v.      Dropdown
    vi.     List Group
    vii.    Media Object
    viii.   Modal
    ix.     Pagination
    x.      Panel
    xi.     Popover
    xii.    Progress Bar
    xiii.   Table
    xiv.    Tabs
    xv.     Tooltips

6.  App Global Styles
    i.      Generic Classes
    ii.     Base Styles
    iii.    List
    iv.     Card
    v.      Miscellaneous
    vi.     Print Styles

9.  Third party Vendor Overrides
    i.      Select 2
    ii.     Slick Carousel
    iii.    NoUiSlider
    iv.     Waves
    v.      Light Gallery
    vi.     RateYo
    vii.    SweetAlert

--------------------------------------------------------------------*/


/*---------------------------------------
    1. Site Variable and Mixins
----------------------------------------*/
@import "./assets/scss/variables";
@import "./assets/scss/mixins";


/*---------------------------------------
    2. Bootstrap
----------------------------------------*/
@import "./assets/scss/bootstrap-imports/bootstrap-imports";


/*---------------------------------------
    1. Site Styles
----------------------------------------*/
@import "./assets/scss/fonts";
@import "./assets/scss/base";
@import "./assets/scss/layout";
@import "./assets/scss/charts";
@import "./assets/scss/toggle-switch";
@import "./assets/scss/listview";
@import "./assets/scss/page-loader";
/* Authentication Login/Register/Reset */
@import "./assets/scss/auth";


/*---------------------------------------
    5. Bootstrap Overrides
----------------------------------------*/
@import "assets/scss/bootstrap-overrides/dropdown";
@import "assets/scss/bootstrap-overrides/media";
@import "assets/scss/bootstrap-overrides/progress";
@import "assets/scss/bootstrap-overrides/card";
@import "assets/scss/bootstrap-overrides/buttons";
@import "assets/scss/bootstrap-overrides/tables";
@import "assets/scss/bootstrap-overrides/forms";
@import "assets/scss/bootstrap-overrides/input-groups";
@import "assets/scss/bootstrap-overrides/custom-forms";
@import "assets/scss/bootstrap-overrides/pagination";
@import "assets/scss/bootstrap-overrides/datepicker";
@import "assets/scss/bootstrap-overrides/timepicker";
@import "assets/scss/bootstrap-overrides/alerts";
@import "assets/scss/bootstrap-overrides/close";
@import "assets/scss/bootstrap-overrides/breadcrumbs";
@import "assets/scss/bootstrap-overrides/accordion";
@import "assets/scss/bootstrap-overrides/carousel";
@import "assets/scss/bootstrap-overrides/modal";
@import "assets/scss/bootstrap-overrides/popovers";
@import "assets/scss/bootstrap-overrides/ratings";
@import "assets/scss/bootstrap-overrides/sortable";
@import "assets/scss/bootstrap-overrides/tabs";
@import "assets/scss/bootstrap-overrides/tooltip";


/*---------------------------------------
    5. Vendor Overrides
----------------------------------------*/
@import "./assets/scss/vendor-overrides/flot";
@import "./assets/scss/vendor-overrides/sparklines";
@import "./assets/scss/vendor-overrides/select2";
@import "./assets/scss/vendor-overrides/dropzone";
@import "./assets/scss/vendor-overrides/angular2-color-picker";
@import "./assets/scss/vendor-overrides/nouislider";
@import "assets/scss/vendor-overrides/_http-loader";
@import "assets/scss/vendor-overrides/_ngx-toastr";


/*---------------------------------------
    5. App Global Styles
----------------------------------------*/
@import "./assets/scss/misc";
@import "./assets/scss/app-styles/info-forms";
@import "./assets/scss/app-styles/ng2-image-upload";
@import "./assets/scss/app-styles/common-style";
